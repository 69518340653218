<div class="container">
  <prlw-title-logo appearance="transparent"></prlw-title-logo>
  <div class="link">Условия использования</div>
  <div class="container gap">
    <div
      class="menu-item cursor"
      [class.active]="item.active"
      *ngFor="let item of menuItems"
      (click)="eventClick.emit(item)"
    >
      {{ item.title }}
    </div>
  </div>
  <div class="link" (click)="eventShowFeedbackOverlay.emit()">
    Написать в поддержку
  </div>
</div>
