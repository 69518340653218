import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TMobileSubmenuItem } from '../mobile-menu/mobile-menu.component';
import { TitleLogoContainer } from '../header/title-logo/title-logo.container';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'prlw-mobile-footer-ui',
  imports: [CommonModule, TitleLogoContainer],
  templateUrl: './mobile-footer.component.html',
  styleUrl: './mobile-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileFooterComponent {
  @Input() menuItems: TMobileSubmenuItem[] | null = [];

  @Output() eventClick = new EventEmitter<TMobileSubmenuItem>();
  @Output() eventShowFeedbackOverlay = new EventEmitter<void>();
}
