import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject, of, timer } from 'rxjs';
import {
  LastTradingSessionDateData,
  MARKET_TIME_GATEWAY,
  MarketTimeData,
  MarketTimeGateway,
} from '@prlw/core/market-time/market-time-gateway.interface';
import {
  MarketTime,
  MarketTimeTarget,
} from '@prlw/core/market-time/market-time.entity';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  retry,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

type tradingTime = {
  responseDate: number;
  timeToStart: number;
  timeToEnd: number;
};

const TIMEOUT = 1000;
const respCount = 3;

@Injectable({
  providedIn: 'root',
})
export class MarketTimeController implements OnDestroy {
  public marketGateway$: Observable<MarketTimeData>;
  public timerSpot$: Observable<MarketTime>;
  public timerFutures$: Observable<MarketTime>;
  private readonly marketTime$: Subject<tradingTime>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(MARKET_TIME_GATEWAY)
    private readonly marketTimeGateway: MarketTimeGateway,
  ) {
    this.marketTime$ = new ReplaySubject<tradingTime>(1);
    this.marketGateway$ = marketTimeGateway.getTradingTime().pipe(
      retry({
        count: respCount,
        delay: TIMEOUT,
      }),
    );
    this.updateMarketTime();
    this.timerSpot$ = this.marketTime$.asObservable().pipe(
      switchMap((response: tradingTime) =>
        timer(0, TIMEOUT).pipe(
          map(() => {
            let { timeToStart, timeToEnd } = response;
            const { responseDate } = response;
            const currentTime = Date.now();
            const deltaTime = Math.abs(
              Math.floor((currentTime - responseDate) / TIMEOUT),
            );
            timeToStart -= deltaTime;
            timeToEnd -= deltaTime;
            return { timeToStart, timeToEnd };
          }),
          map((marketTime) => this.getTradingTime(marketTime)),
        ),
      ),
    );

    this.timerFutures$ = this.marketTime$.asObservable().pipe(
      switchMap((response: tradingTime) =>
        timer(0, TIMEOUT).pipe(
          map(() => {
            let { timeToStart, timeToEnd } = response;
            const { responseDate } = response;
            const currentTime = Date.now();
            const deltaTime = Math.abs(
              Math.floor((currentTime - responseDate) / TIMEOUT),
            );
            timeToStart -= deltaTime;
            timeToEnd -= deltaTime;
            timeToEnd += 10800;
            return { timeToStart, timeToEnd };
          }),
          map((marketTime) => this.getTradingTime(marketTime)),
        ),
      ),
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getTradingTime(marketTime: {
    timeToStart: number;
    timeToEnd: number;
  }): MarketTime {
    const { timeToStart, timeToEnd } = marketTime;
    if (timeToStart > 0 && timeToStart < timeToEnd) {
      return {
        target: MarketTimeTarget.Start,
        value: timeToStart,
      };
    } else if (timeToEnd > 0) {
      return {
        target: MarketTimeTarget.End,
        value: timeToEnd,
      };
    }
    this.updateMarketTime();
    return {
      target: MarketTimeTarget.End,
      value: timeToEnd,
    };
  }

  public setTradingTime(): Observable<tradingTime> {
    return this.marketGateway$.pipe(
      map((marketResponse: MarketTimeData) => {
        const data = {
          responseDate: Date.now(),
          timeToStart: marketResponse.tradingTimeBeforeStart,
          timeToEnd: marketResponse.tradingTimeEnd,
        };
        this.marketTime$.next(data);
        return data;
      }),
    );
  }

  public updateMarketTime(): void {
    this.setTradingTime().pipe(first(), takeUntil(this.destroy$)).subscribe();
  }

  public get isTradingActive$(): Observable<boolean> {
    return this.timerSpot$.pipe(
      map((marketTime) => marketTime.target === MarketTimeTarget.End),
      distinctUntilChanged(),
    );
  }

  lastTradingSessionDate$ = this.marketTimeGateway
    .getLastTradingSessionDate()
    .pipe(
      catchError(() =>
        of<LastTradingSessionDateData>({
          lastTradingSessionDate: new Date().toISOString(),
        }),
      ),
      map(({ lastTradingSessionDate }) => lastTradingSessionDate),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );
}
