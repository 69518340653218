import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMinimalComponent } from './minimal/header-minimal.component';
import { HeaderPrimaryComponent } from './primary/header-primary.component';
import { RouterModule } from '@angular/router';
import { MarketUiModule } from '@prlw/common/market/market-ui.module';
import { IconsModule } from '@prlw/libs/icons/icons.module';
import { DemoAccessModule } from '../../features/demo-access/demo-access.module';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';
import { FeedbackHelpButtonComponent } from 'src/app/features/feedback/feedback-help-button/feedback-help-button.component';
import { MobileMenuSandwichButtonComponent } from '../mobile-menu-sandwich-button/mobile-menu-sandwich-button.component';
import { TitleLabelContainer } from './title-label/title-label.container';
import { TitleLogoContainer } from './title-logo/title-logo.container';
import { TitleProfileContainer } from './title-profile/title-profile.container';

@NgModule({
  declarations: [HeaderMinimalComponent, HeaderPrimaryComponent],
  exports: [HeaderMinimalComponent, HeaderPrimaryComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconsModule,
    MarketUiModule,
    DemoAccessModule,
    ResponsiveDirective,
    FeedbackHelpButtonComponent,
    TitleLabelContainer,
    TitleLogoContainer,
    TitleProfileContainer,
    MobileMenuSandwichButtonComponent,
  ],
})
export class HeaderModule {}
