import { Layout } from '@prlw/common/layout/layout.enum';
import { IPage } from '@prlw/common/page/page.interface';
import { TCommonPaneAppearance } from '../common-pane/common-pane.component';

export class PageContext {
  constructor(
    public readonly pageTitle: string,
    public readonly headerTitle: string,
    public readonly layout: Layout,
    public readonly titleLabelSvgName: string | null = null,
    public readonly titleSvgName: string = 'logo-en',
    public readonly commonPaneAppearance: TCommonPaneAppearance = 'white',
  ) {}

  public static fromPage(page: IPage): PageContext {
    return new PageContext(
      page.pageTitle,
      page.headerTitle,
      page.layout,
      page.titleLabelSvgName,
      page.titleSvgName,
      page.commonPaneAppearance,
    );
  }
}
