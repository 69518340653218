import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  MobileMenuComponent,
  TMobileSubmenuItem,
} from './mobile-menu.component';
import { Router } from '@angular/router';

export const mobileMenuItems: TMobileSubmenuItem[] = [
  {
    icon: 'nav-spot',
    active: false,
    title: 'Спотовые цены',
    routerLink: '/monitor',
  },
  {
    icon: 'nav-analytics',
    active: false,
    title: 'Аналитика',
    routerLink: '/analytics',
  },
  {
    icon: 'nav-deals',
    active: false,
    title: 'Все сделки',
    routerLink: '/deals',
  },
  {
    icon: 'nav-small-wholesale',
    active: false,
    title: 'ХАБ',
    routerLink: '/region',
  },
];

@Component({
  selector: 'prlw-mobile-menu',
  standalone: true,
  imports: [MobileMenuComponent],
  templateUrl: './mobile-menu.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuContainer {
  @Output() eventClose = new EventEmitter<void>();

  constructor(private router: Router) {}

  handleMobileSubmenuItemClick(item: TMobileSubmenuItem) {
    this.eventClose.emit();
    this.router.navigate([item.routerLink]);
  }

  get items(): TMobileSubmenuItem[] {
    return mobileMenuItems.map((item) => ({
      ...item,
      active: this.router.url.includes(item.routerLink),
    }));
  }
}
