<prlw-common-pane [appearance]="(isTransparent$ | async) || appearance">
  <img
    *ngIf="(xsmall$ | async) === false && svgName; else text"
    src="/assets/logo/{{ svgName }}.svg"
    aria-hidden="true"
    alt="{{ svgName }}"
  />
  <ng-template #text>
    <div class="title" prlwResponsive>{{ title }}</div>
  </ng-template>
  <div *ngIf="isPro" class="pro">PRO</div>
</prlw-common-pane>
