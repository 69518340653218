import { Observable, Observer, filter, map } from 'rxjs';

type TSuccess<T> = {
  done: true;
  pending: false;
  result: T;
};

type TPending = {
  done: false;
  pending: true;
};

type TError = {
  done: false;
  pending: false;
  error: Error;
};

export type TObservableWithPending<T> = TSuccess<T> | TPending | TError;

export const asOwpSuccess = <T>(result: T): TSuccess<T> => ({
  done: true,
  pending: false,
  result,
});
export const asOwpError = (error: Error): TError => ({
  done: false,
  pending: false,
  error,
});

const owpIsSuccess = <T>(owp: TObservableWithPending<T>): owp is TSuccess<T> =>
  'result' in owp;

const owpIsError = <T>(owp: TObservableWithPending<T>): owp is TError =>
  'error' in owp;

export const mapResult = <T>(obs$: Observable<TObservableWithPending<T>>) =>
  obs$.pipe(
    filter(owpIsSuccess),
    map((owp) => owp.result),
  );

export const mapPending = <T>(obs$: Observable<TObservableWithPending<T>>) =>
  obs$.pipe(map((owp) => owp.pending));

export const mapError = <T>(obs$: Observable<TObservableWithPending<T>>) =>
  obs$.pipe(
    filter(owpIsError),
    map((owp) => owp.error),
  );

export const observableWithPending = <T>(
  observable$: Observable<T>,
  completeOnFirst = false,
): Observable<TObservableWithPending<T>> =>
  new Observable((observer: Observer<TObservableWithPending<T>>) => {
    observer.next({ done: false, pending: true });
    const subscription = observable$.subscribe({
      error(error) {
        observer.next(asOwpError(error));
        observer.complete();
      },
      next(result) {
        observer.next(asOwpSuccess(result));
        if (completeOnFirst) {
          observer.complete();
        }
      },
      complete() {
        observer.complete();
      },
    });
    return function unsubscribe() {
      subscription.unsubscribe();
    };
  });
