import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TitleLabelComponent } from './title-label.component';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { CommonModule } from '@angular/common';
import { PageContextService } from '@prlw/common/page/page-context.service';
import { map } from 'rxjs';

@Component({
  selector: 'prlw-title-label',
  standalone: true,
  imports: [CommonModule, TitleLabelComponent],
  templateUrl: './title-label.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleLabelContainer {
  constructor(
    private readonly profileController: ProfileController,
    private readonly pageContextService: PageContextService,
  ) {}

  isPro$ = this.profileController.isPro$;

  title$ = this.pageContextService.pageContext$.pipe(
    map((context) => context.headerTitle),
  );
  svgName$ = this.pageContextService.pageContext$.pipe(
    map((context) => context.titleLabelSvgName),
  );
  appearance$ = this.pageContextService.pageContext$.pipe(
    map((context) => context.commonPaneAppearance),
  );
}
