import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageTitleService } from '@prlw/common/page/page-title.service';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';
import { PagePreloaderService } from './common/page/page-preloader.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'prlw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private readonly pageTitleService: PageTitleService,
    private readonly responsiveService: ResponsiveService,
    private readonly pagePreloaderService: PagePreloaderService,
    private readonly googleTagManagerService: GoogleTagManagerService,
  ) {
    pageTitleService.setPageTitle('');
    googleTagManagerService.addGtmToDom();
  }

  xsmall$ = this.responsiveService.xsmall$;
}
